import React, { useState, useEffect, useRef } from 'react';
import './App.css';

const videoUrls = [
  'https://packaged-media.redd.it/miuc6z2tasgd1/pb/m2-res_640p.mp4?m=DASHPlaylist.mpd&v=1&s=8de0899db89cc9b9e92edea6eaea56e5ef756b38#t=0',
    'https://packaged-media.redd.it/e0mselr1sogd1/pb/m2-res_824p.mp4?m=DASHPlaylist.mpd&v=1&s=288d126501ea6e8a4b322e10f304c5cb8a0810dd#t=0',
    'https://packaged-media.redd.it/h39qplxgsqgd1/pb/m2-res_1920p.mp4?m=DASHPlaylist.mpd&v=1&s=9d1df46c74782d8759f656748ddd2029ed249c3e#t=0',
    'https://packaged-media.redd.it/0l36ybj9w6hd1/pb/m2-res_640p.mp4?m=DASHPlaylist.mpd&v=1&s=6b9d90d932ad6445e5146394a8852f854502ba96#t=0',
    'https://packaged-media.redd.it/4tt3b3miu8hd1/pb/m2-res_560p.mp4?m=DASHPlaylist.mpd&v=1&s=fc6f8bc82a1edc537d9a9c18d3e60afc2c4c0357#t=0',
  // Add more video URLs as needed
];

const getRandomVideoUrl = () => videoUrls[Math.floor(Math.random() * videoUrls.length)];

const App = () => {
  const [videoSources, setVideoSources] = useState([getRandomVideoUrl(), getRandomVideoUrl(), getRandomVideoUrl()]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null);
  const videoRefs = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      const { scrollTop, clientHeight, scrollHeight } = container;

      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setVideoSources(prevSources => [...prevSources, getRandomVideoUrl()]);
      }

      const newCurrentIndex = Math.floor(scrollTop / clientHeight);
      if (newCurrentIndex !== currentIndex) {
        setCurrentIndex(newCurrentIndex);
      }
    };

    const container = containerRef.current;
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [currentIndex]);

  useEffect(() => {
    const videos = videoRefs.current;
    videos.forEach((video, index) => {
      if (index === currentIndex) {
        video.muted = false;
        video.play().catch((error) => {
          console.error('Error trying to play the video:', error);
        });
      } else {
        video.pause();
        video.muted = true;
      }
    });
  }, [currentIndex, videoSources]);

  useEffect(() => {
    const firstVideo = videoRefs.current[0];
    if (firstVideo) {
      firstVideo.muted = false; // Unmute the first video
      firstVideo.play().catch((error) => {
        console.error('Error trying to autoplay the first video:', error);
      });
    }
  }, []);

  return (
    <div id="reelsContainer" ref={containerRef} className="reels-container">
      {videoSources.map((src, index) => (
        <video
          key={index}
          className="reel"
          src={src}
          loop
          muted
          ref={el => videoRefs.current[index] = el}
          playsInline
          autoPlay
        />
      ))}
    </div>
  );
};

export default App;
